import React from "react"

import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundSection from "@/components/BackgroundSection"
import Quote from "@/components/Quote"
import { routes } from "@/utils/routes"
import { graphql, Link } from "gatsby"
import WistiaPlayer from "@/components/WistiaPlayer"

type PageProps = {
  data: any
}

export const pageQuery = graphql`
query SocialMobilityQuery {
  sanityVideo(position: {eq: 3}) {
    title
    placeholder {
      asset {
        url
      }
    }
    videoUrl
  }
}
`

const SocialMobility: React.FC<PageProps> = ({ data }) => {
  return (
    <Layout
      sectionOne={
        <>
          <StaticImage
            src="../../static/images/2024/02/bursaries-header.jpg"
            className="absolute inset-0 -z-[1]"
            alt="Donate"
          />
          <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
            <h1 className="pb-2 md:pb-8 responsive-heading">
              BURSARY  <span className="text-primary">PROGRAMME</span>
            </h1>
            <div className="pt-8 flex justify-center">
              <img src="/images/down.png" alt="Down arrow" />
            </div>
          </div>
        </>
      }
    >
      <SEO title="Social Mobility" />

      <div className="pt-24 pb-8">
        <div className="container">
          <h2 className="uppercase text-[36px] sm:text-[46px] font-medium leading-[0.9] pb-4 mb-8">
            Since its inception, RBAI has{" "}
            <span className="text-primary">educated</span> boys from across Belfast
            and beyond, regardless of their{" "}
            <span className="text-primary">background</span>
          </h2>

          {data.sanityVideo.videoUrl != null ?
            <WistiaPlayer
              videoStyle={{
                width: "100%",
                height: "100%",
              }}
              videoID={data.sanityVideo.videoUrl}
              className="aspect-video"
            />
            :
            <img src={data.sanityVideo.placeholder.asset.url} alt={data.sanityVideo.title} className="w-full" />
          }

          <div className="md:mt-8 mb-8 grid gap-8 grid-cols-1 md:grid-cols-2">
            <div>
            </div>

            <div className="w-full uppercase flex items-center justify-self-end">
              <h2 className="text-[42px] leading-none pt-4 lg:pl-12">
                <Link
                  className="text-primary hover:opacity-90"
                  to={routes.donate()}
                >
                  Donate Now
                </Link>
              </h2>
            </div>
          </div>

        </div>
      </div>

      <BackgroundSection
        paragraphs={
          <>
            <p>
              The Governors recognise the importance played by education in social
              mobility, particularly given evidence of low educational aspiration
              and attainment amongst boys from disadvantaged communities.
            </p>
            <p>
              Fees remain at a fraction of those charged by public schools
              offering a similar pupil experience in Great Britain. However some
              parents of prospective pupils and those already at the School have
              difficulty funding their son’s education.
            </p>
            <p>
              The provision of bursaries supports social mobility and is central
              to RBAI’s ethos. We are seeking donations to
              ensure that we continue to provide the opportunity of a first class
              education to all.
            </p>
          </>
        }
        paragraphsClassName="max-w-[400px] ml-auto"
        bg="/images/2023/03/social-mobility.jpg"
      />

      <div className="container pt-20 pb-16">
        <div>
          <h2 className="responsive-heading !leading-[0.9]">
            IMPACTING
            <br />
            <span className="text-primary">PUPILS</span>{" "}
          </h2>
          {/* <p className="mt-8">
            Developing a sustainable bursary income will:
          </p> */}
        </div>
        {/* <div className="grid gap-8 md:grid-cols-2 pt-20">
          <Feature
            description="Underwrite our commitment to the founding principles of the
                    School, ensuring that no eligible pupil loses the opportunity
                    of an education at RBAI."
            freeWidth
          />
          <Feature
            description="Maintaining RBAI as the premier school for competitive
                    swimming in Northern Ireland."
            freeWidth
          />
        </div> */}
      </div>

      <div className="container grid md:grid-cols-2 px-4">
        <Quote
          quote="At Inst, taking part is much more important than your background.
            Alongside learning, my bursary allowed me to gain so much
            confidence from the range of extra activities."
          author="Anonymous Pupil"
          className="bg-[#111111]"
        />

        <Quote
          quote="When our circumstances changed, we were extremely grateful for
            the Bursary Programme, ensuring our son’s education was not
            impacted during a challenging time."
          author="Anonymous Parent"
          className="bg-[#faba20]"
        />
      </div>
    </Layout>
  )
}

export default SocialMobility
